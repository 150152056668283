<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row class="py-3">
          <div class="p-4">
            <basic-input
              label="Nội dung câu hỏi"
              placeholder="Nội dung câu hỏi"
              name="level"
              class="filter"
              :value.sync="filters.body"
              @keyup.native.enter="loadData"
            />
          </div>
          <div class="p-4">
            <basic-select
              label="Trạng thái"
              placeholder="--- Chọn trạng thái ---"
              name="status"
              :solid="false"
              :allowEmpty="true"
              value-label="text"
              track-by="value"
              class="filter"
              :options="statuses"
              changeValueByObject
              multiple
              :value.sync="filters.selectedStatuses"
              @update:value="selectedStatuses($event, true)"
            />
          </div>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-form-filter"
          class="dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-800">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Nội dung câu hỏi"
                    placeholder="Nội dung câu hỏi"
                    name="content"
                    :value.sync="filters.body"
                  />
                </b-col>
                <b-col>
                  <basic-input
                    label="Khách hàng"
                    placeholder="Nhập tên khách hàng"
                    name="name"
                    :value.sync="filters.customer"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-select
                    label="Trạng thái"
                    placeholder="--- Chọn trạng thái ---"
                    name="status"
                    :solid="false"
                    :allowEmpty="true"
                    value-label="text"
                    track-by="value"
                    :options="statuses"
                    :value.sync="filters.selectedStatuses"
                    changeValueByObject
                    multiple
                    @update:value="selectedStatuses($event)"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <label>Ngày tạo</label>
                  <b-row>
                    <b-col>
                      <date-picker
                        placeholder="--- Ngày tạo từ ---"
                        name="startCreateDate"
                        input-class="form-control"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="format"
                        :disabled-date="disabledBeforeFromCreateDate"
                        v-model="filters.startCreateDate"
                        :append-to-body="false"
                      />
                    </b-col>
                    <b-col>
                      <date-picker
                        placeholder="--- Ngày tạo đến ---"
                        name="endCreateDate"
                        input-class="form-control"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="format"
                        :disabled-date="disabledBeforeToCreateDate"
                        v-model="filters.endCreateDate"
                        :append-to-body="false"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <basic-select
                    label="Chủ đề"
                    placeholder="--- Chọn chủ đề ---"
                    name="module"
                    :solid="false"
                    :allowEmpty="true"
                    :options="modules"
                    changeValueByObject
                    :value.sync="filters.selectedModules"
                    multiple
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <label>Ngày cập nhật</label>
                  <b-row>
                    <b-col>
                      <date-picker
                        placeholder="--- Ngày cập nhật từ ---"
                        name="startUpdateDate"
                        input-class="form-control"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="format"
                        :disabled-date="disabledBeforeFromUpdateDate"
                        v-model="filters.startUpdateDate"
                        :append-to-body="false"
                      />
                    </b-col>
                    <b-col>
                      <date-picker
                        placeholder="--- Ngày cập nhật đến ---"
                        name="endUpdateDate"
                        input-class="form-control"
                        type="date"
                        format="DD/MM/YYYY"
                        value-type="format"
                        :disabled-date="disabledBeforeToUpdateDate"
                        v-model="filters.endUpdateDate"
                        :append-to-body="false"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <basic-select
                    label="Chuyên gia"
                    placeholder="--- Chọn chuyên gia ---"
                    name="expert"
                    :solid="false"
                    :allowEmpty="true"
                    :options="professors"
                    changeValueByObject
                    :value.sync="filters.selectedProfessors"
                    multiple
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </template>
    </basic-subheader>
    <Wrapper>
      <template-table
        :column="column"
        :data="questions"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        @sortBy="sortRequest"
      >
        <template v-slot:body="{ item }">
          <td class="cell-overflow">
            <div
              class="text-overflow"
              :class="{
                'text-underline text-primary cursor-pointer':
                  item.status !== QUESTION_STATUSES.CLOSE,
              }"
              @click="openEditModal(item)"
            >
              {{ item.body }}
            </div>
          </td>
          <td>
            <action-dropdown
              :value="item"
              :show_copy="false"
              :show_view="true"
              :show_delete="false"
              :show_edit="showActionEdit(item.status) && isWritePermission"
              @view="openModal"
              @edit="openEditModal"
              :boundary="questions.length > 3 ? 'scrollParent' : 'window'"
            >
              <template>
                <b-dropdown-text
                  v-if="item.isViewProfile"
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="goToUserDashboard(item)"
                >
                  <a class="navi-link text-primary">
                    <b-icon-grid class="mr-2" scale="1.2" />
                    <span class="navi-text text-primary"
                      >Xem user dashboard</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider v-if="item.isViewProfile" />
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="goToUserDetail(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/user-info.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết user app</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="showActionEdit(item.status) && isWritePermission"
                />
                <template
                  v-if="showActionEdit(item.status) && isWritePermission"
                >
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="showPopupClose(item)"
                  >
                    <a class="navi-link text-primary">
                      <b-icon-x-square class="mr-2" scale="1.2" />
                      <span class="navi-text text-primary">Đóng câu hỏi</span>
                    </a>
                  </b-dropdown-text>
                </template>
                <b-dropdown-divider />
                <b-dropdown-text
                  v-if="isWritePermission"
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="showPopupDelete(item)"
                >
                  <a class="navi-link text-danger">
                    <b-icon-trash class="mr-2" scale="1.2" />
                    <span class="navi-text text-danger">Xóa câu hỏi</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="openModalLessonM(item)"
                >
                  <a class="navi-link text-primary">
                    <b-icon-pencil class="mr-2" scale="1.2" />
                    <span class="navi-text text-primary">Cập nhật chủ đề</span>
                  </a>
                </b-dropdown-text>
              </template>
            </action-dropdown>
          </td>
          <td>
            <div class="status" :class="getClassByStatus(item.statusPortal)">
              {{ getStatus(item.statusPortal) }}
            </div>
          </td>

          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.creator }}
            </div>
          </td>
          <td>
            {{ convertTimestampToDate(item.createDateTime) }}
          </td>
          <td>
            {{ convertTimestampToDate(item.updateDateTime) }}
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.lessonModule ? item.lessonModule.name : '' }}
            </div>
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.professor.fullName }}
            </div>
          </td>
          <td class="cell-overflow">
            <div class="text-overflow">
              {{ item.importer }}
            </div>
          </td>
        </template>
      </template-table>
      <modal-question-detail
        v-if="showModal"
        :show="showModal"
        :id="selectedQuestion.id"
        :type="modalType"
        @reset="resetModal"
        @reload-data="loadData"
        @change-type="changeModalType"
      />
      <modal-update-lesson-module
        v-if="showModalLessonM"
        :show="showModalLessonM"
        :id="selectedQuestion.id"
        @reload-data="loadData"
        @reset-lesson-module="resetModalLessonModule"
      />
    </Wrapper>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import { createNamespacedHelpers } from 'vuex';
import {
  GET_QUESTIONS,
  DELETE_QUESTION,
  CLOSE_QUESTION,
} from '@/core/services/store/question.module';
import { MODAL_TYPE, QUESTION_STATUSES } from '@/core/plugins/constants';
const { mapActions, mapState } = createNamespacedHelpers('question');
export default {
  name: 'QuestionResponseList',
  components: {
    'date-picker': DatePicker,
    'modal-question-detail': () => import('./components/ModalDetail.vue'),
    'modal-update-lesson-module': () =>
      import('./components/ModalLessonModule.vue'),
  },
  data() {
    return {
      QUESTION_STATUSES,
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      column: [
        {
          key: 'body',
          label: 'Nội dung câu hỏi',
          sortable: false,
          style: {
            width: '350px',
            'min-width': '250px',
          },
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '150px',
            'min-width': '140px',
          
          },
           
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          style: {
            width: '200px',
            'min-width': '150px',
          },
        },
        {
          key: 'create_datetime',
          label: 'Ngày tạo',
          sortable: true,
          style: {
            'min-width': '120px',
            width: '120px',
          },
        },
        {
          key: 'update_datetime',
          label: 'Ngày cập nhật',
          sortable: true,
          style: {
            'min-width': '150px',
            width: '150px',
          },
        },
        {
          key: 'module',
          label: 'Chủ đề',
          sortable: false,
          style: {
            width: '300px',
            'min-width': '200px',
          },
        },
        {
          key: 'expert',
          label: 'Chuyên gia',
          sortable: false,
          style: {
            width: '300px',
            'min-width': '200px',
          },
        },
        {
          key: 'updater_id',
          label: 'Người nhập',
          sortable: true,
          style: {
            width: '300px',
            'min-width': '200px',
          },
        },
      ],
      filters: {
        body: null,
        customer: null,
        type: null,
        lessonLevels: null,
        selectedModules: null,
        selectedStatuses: null,
        selectedProfessors: null,
        endCreateDate: null,
        startCreateDate: null,
        startUpdateDate: null,
        endUpdateDate: null,
      },
      showModal: false,
      showModalLessonM: false,
      sort: {
        by: null,
        order: null,
      },
      statuses: [
        {
          text: 'Đã phản hồi',
          value: QUESTION_STATUSES.WAITING,
        },
        {
          text: 'Chưa phản hồi',
          value: QUESTION_STATUSES.OPEN,
        },
        {
          text: 'Đóng',
          value: QUESTION_STATUSES.CLOSE,
        },
      ],
      modalType: MODAL_TYPE.DETAIL,
      selectedQuestion: null,
      professors: [],
      modules: [],
    };
  },
  computed: {
    ...mapState(['questions']),
    searchParams() {
      const filter = {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        body: this.filters.body,
        username: this.filters.customer,
        createDateStart: this.convertDateToTimestamp(
          this.filters.startCreateDate,
        ),
        createDateEnd: this.convertDateToTimestamp(this.filters.endCreateDate),
        updateDateStart: this.convertDateToTimestamp(
          this.filters.startUpdateDate,
        ),
        updateDateEnd: this.convertDateToTimestamp(this.filters.endUpdateDate),
      };
      const params = new URLSearchParams();
      for (const property in filter) {
        if (filter[property]) {
          params.append(property, filter[property]);
        }
      }
      if (this.filters.selectedStatuses?.length) {
        this.filters.selectedStatuses.forEach((el) => {
          params.append('statusPortals', el.value);
        });
      }
      if (this.filters.selectedModules?.length) {
        this.filters.selectedModules.forEach((el) => {
          params.append('lessonModuleIds', el.id);
        });
      }
      if (this.filters.selectedProfessors?.length) {
        this.filters.selectedProfessors.forEach((el) => {
          params.append('professorIds', el.id);
        });
      }
      return params;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
  },
  async created() {
    await Promise.all([this.getPrepareSearchData(), this.loadData()]);
  },
  methods: {
    ...mapActions({ GET_QUESTIONS, DELETE_QUESTION, CLOSE_QUESTION }),
    async getPrepareSearchData() {
      try {
        const { meta, data, error } = await this.$api.get(
          '/Question/GetExtend',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.professors = data.professor.map((el) => ({
          id: el.id,
          name: el.fullName,
        }));
        this.modules =
          data.lessonModule &&
          data.lessonModule
            .filter((m) => {
              return m !== null;
            })
            .map((el) => ({
              id: el.id,
              name: el.name,
            }));
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.loadData();
    },
    resetRequest() {
      this.filters = {
        body: null,
        customer: null,
        type: null,
        lessonLevels: null,
        selectedModules: null,
        selectedStatuses: null,
        endCreateDate: null,
        startCreateDate: null,
        startUpdateDate: null,
        endUpdateDate: null,
        selectedProfessors: null,
      };
      document.body.click();
      this.loadData();
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    goToUserDetail(item) {
      this.$router.push({
        name: 'user_app_view',
        params: {
          id: item.patientId,
        },
      });
    },
    openModal(item) {
      this.selectedQuestion = item;
      this.showModal = true;
    },
    openModalLessonM(item) {
      this.selectedQuestion = item;
      this.showModalLessonM = true;
    },
    openEditModal(item) {
      if (item.statusPortal === QUESTION_STATUSES.CLOSE) return;
      this.selectedQuestion = item;
      this.modalType = MODAL_TYPE.EDIT;
      this.showModal = true;
    },
    showPopupDelete(item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa câu hỏi này không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteQuestion(item);
          }
        }.bind(this),
      );
    },
    showPopupClose(item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái câu hỏi này thành đóng không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.closeQuestion(item);
          }
        }.bind(this),
      );
    },
    async closeQuestion(item) {
      const { error } = await this.CLOSE_QUESTION(item.id);

      if (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loadData();
    },
    async deleteQuestion(item) {
      const { error } = await this.DELETE_QUESTION(item.id);

      if (error) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loadData();
    },
    resetModal() {
      this.modalType = MODAL_TYPE.DETAIL;
      this.showModal = false;
      this.selectedQuestion = null;
    },
    resetModalLessonModule() {
      this.showModalLessonM = false;
      this.selectedQuestion = null;
    },
    async loadData() {
      const { paging } = await this.GET_QUESTIONS(this.searchParams);
      this.paging.total = paging.total;
    },
    getStatus(status) {
      switch (status) {
        case QUESTION_STATUSES.CLOSE:
          return 'Đóng';
        case QUESTION_STATUSES.WAITING:
          return 'Đã phản hồi';
        case QUESTION_STATUSES.OPEN:
          return 'Chưa phản hồi';

        default:
          return 'Đã phản hồi';
      }
    },
    getClassByStatus(status) {
      switch (status) {
        case QUESTION_STATUSES.CLOSE:
          return 'closed';
        case QUESTION_STATUSES.WAITING:
          return 'waiting';
        case QUESTION_STATUSES.OPEN:
          return 'open';

        default:
          return 'answered';
      }
    },
    disabledBeforeToCreateDate(date) {
      if (this.filters.startCreateDate) {
        const newDate = this.convertToISODate(this.filters.startCreateDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromCreateDate(date) {
      if (this.filters.endCreateDate) {
        const newDate = this.convertToISODate(this.filters.endCreateDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
    disabledBeforeToUpdateDate(date) {
      if (this.filters.startUpdateDate) {
        const newDate = this.convertToISODate(this.filters.startUpdateDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromUpdateDate(date) {
      if (this.filters.endUpdateDate) {
        const newDate = this.convertToISODate(this.filters.endUpdateDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
    showActionEdit(status) {
      return status !== QUESTION_STATUSES.CLOSE;
    },
    changeModalType(type) {
      this.modalType = type;
    },
    selectedStatuses(value, isReloadData = false) {
      if (!value || !value.length) {
        this.filters.selectedStatuses = null;
      }
      isReloadData && this.loadData();
    },
    goToUserDashboard(item) {
      this.$router.push({
        name: 'user_dashboard',
        params: {
          id: item.patientId,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-800 {
  min-width: 800px;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.status {
  width: 140px;
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  
  &.open {
    background: #21a567;
  }

  &.waiting {
    background: #f58220;
  }

  &.closed {
    background: #888c9f;
  }
}

.text-underline {
  text-decoration: underline;
}
</style>

<style lang="scss">
.filter {
  min-width: 250px;
}
</style>
